var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

$(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
  var skuData = prodcat.data.getSku(skuBaseId);
  var $smooshImg = $('.quickshop__smoosh--mobile', this);

  if (!$smooshImg.length) {
    return null;
  }
  if (!_.isArray(skuData.IMAGE_SMOOSH_L)) {
    return null;
  }
  if (!skuData.IMAGE_SMOOSH_L.length) {
    return null;
  }

  $smooshImg.attr('src', skuData.IMAGE_SMOOSH_L[0]);
});

$(document).on('click', '.js-quickshop-smoosh', function (e, skuBaseId) {
  var $this = $(this);

  window.location.href = $this.data('base-url') + ($this.data('shade-path') || '');
});
